
export const oopsCourseModuleList = [
  {
    moduleNumber: 1,
    moduleName: "I. OOPS Introduction",
    chapters: [
      {
        chapterNumber: 1,
        chapterName: "Why do we need OOPS?",
        path: "/blog/why-should-we-learn-oops-concepts-in-programming/",
      },
      {
        chapterNumber: 2,
        chapterName: "Four principles of OOPS",
        path: "/blog/principles-of-object-oriented-programming/",
      },
      {
        chapterNumber: 3,
        chapterName: "OOPS Introduction C++",
        path: "/blog/introduction-to-oops-concepts-in-cpp/",
      },
      {
        chapterNumber: 4,
        chapterName: "OOPS Introduction Java",
        path: "/blog/introduction-to-oops-in-java/",
      },
      {
        chapterNumber: 5,
        chapterName: "OOPS Introduction Python",
        path: "/blog/introduction-to-oops-in-python/",
      },
      {
        chapterNumber: 6,
        chapterName: "Classes and Objets",
        path: "/blog/classe-and-object-in-oops/",
      },
      {
        chapterNumber: 7,
        chapterName: "Constructors in OOPS",
        path: "/blog/constructors-in-java/",
      },
     {
       chapterNumber: 8,
       chapterName: "Message Passing in OOPS",
       path: "/blog/message-passing-oops/",
     },
    ],
  },
  {
    moduleNumber: 2,
    moduleName: "II. Four Pillars of OOPS",
    chapters: [
      {
        chapterNumber: 1,
        chapterName: "Encapsulation in OOPS",
        path: "/blog/encapsulation-in-oops/",
      },
      {
        chapterNumber: 2,
        chapterName: "Abstraction in OOPS",
        path: "/blog/abstraction-in-oops/",
      },
      {
        chapterNumber: 3,
        chapterName: "Inheritance in OOPS",
        path: "/blog/inheritance-in-java/",
      },
      {
        chapterNumber: 4,
        chapterName: "Polymorphism in OOPS",
        path: "/blog/difference-between-compile-time-and-runtime-polymorphism/",
      },
      {
        chapterNumber: 5,
        chapterName: "Method Overloading Java",
        path: "/blog/method-overloading-in-java/",
      },
      {
        chapterNumber: 6,
        chapterName: "Operator Overloading C++",
        path: "/blog/operator-overloading-in-cpp/",
      },
      {
        chapterNumber: 7,
        chapterName: "Abstract Class in Java",
        path: "/blog/abstract-class-in-java/",
      },
      {
        chapterNumber: 8,
        chapterName: "Interface in Java",
        path: "/blog/interface-in-java/",
      },
    ],
  },
  {
    moduleNumber: 3,
    moduleName: "III. OOPS principles",
    chapters: [
      {
        chapterNumber: 1,
        chapterName: "Software Engineering Principles",
        path: "/blog/principles-of-software-engineering/",
      },
      {
       chapterNumber: 2,
       chapterName: "Coupling and Cohesion",
       path: "/blog/cohesion-and-coupling-in-oops/",
     },
      {
        chapterNumber: 3,
        chapterName: "Single Responsibility Principle",
        path: "/blog/single-responsibility-principle-in-oops/",
      },
      {
        chapterNumber: 4,
        chapterName: "Open-Closed Principle",
        path: "/blog/open-close-principle/",
      },
      {
        chapterNumber: 5,
        chapterName: "Liskov Substitution Principle",
        path: "/blog/liskov-substitution-principle/",
      },
      {
        chapterNumber: 6,
        chapterName: "Interface Segregation Principle",
        path: "/blog/interface-segregation-principle/",
      },
      {
        chapterNumber: 7,
        chapterName: "Dependency Inversion Principle",
        path: "/blog/dependency-inversion-principle/",
      },
    ],
  },
  {
    moduleNumber: 4,
    moduleName: "IV. Design Patterns",
    chapters: [
      {
        chapterNumber: 1,
        chapterName: "Factory Method Pattern",
        path: "/blog/factory-design-pattern/",
      },
      {
        chapterNumber: 2,
        chapterName: "Singleton Design Pattern",
        path: "/blog/singleton-design-pattern/",
      },
      {
        chapterNumber: 3,
        chapterName: "Abstract Factory Pattern",
        path: "/blog/abstract-factory-design-pattern/",
      },
      {
        chapterNumber: 4,
        chapterName: "Builder Design Pattern",
        path: "/blog/builder-design-pattern/",
      },
      {
        chapterNumber: 5,
        chapterName: "Proxy Design Pattern",
        path: "/blog/proxy-design-pattern/",
      },
      {
        chapterNumber: 6,
        chapterName: "Facade Design Pattern",
        path: "/blog/facade-design-pattern-java/",
      },
      {
        chapterNumber: 7,
        chapterName: "Observer Design Pattern",
        path: "/blog/observer-design-pattern/",
      },
      {
        chapterNumber: 8,
        chapterName: "Strategy Design Pattern",
        path: "/blog/strategy-design-pattern/",
      },
    ],
  },
]
